import { realizationsTypes } from "./realizationsTypes"

export const realizationsFilters = [
  {
    id: 0,
    label: "Wszystkie Projekty",
    value: realizationsTypes.all,
  },
  {
    id: 1,
    label: "Esport",
    value: realizationsTypes.esport,
  },
  {
    id: 2,
    label: "Produkcja video",
    value: realizationsTypes.videoProduction,
  },
  {
    id: 3,
    label: "Konferencja",
    value: realizationsTypes.conference,
  },
  {
    id: 4,
    label: "Event",
    value: realizationsTypes.event,
  },
  {
    id: 5,
    label: "Dostarczanie sprzętu",
    value: realizationsTypes.equipmentSupply,
  },
]
