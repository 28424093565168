import React, { useEffect } from "react"
import Layout from "../../components/Layout/Layout"
import Seo from "../../components/Seo"
import YellowBox from "../../components/shared/YellowBox/YellowBox"
import styled from "styled-components"
import { device } from "../../components/shared/styles/breakpoints"
import RealizationsTitle from "../../components/RealizationsPage/RealizationsTitle"
import Realizations from "../../components/RealizationsPage/Realizations"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"
import Aos from "aos"
import "aos/dist/aos.css"

const StyledRealizations = styled.div`
  max-width: 980px;
  width: 100%;
  margin: 0 auto;
`

const StyledYellowBox = styled(YellowBox)`
  max-width: 980px;
  min-height: 224px;
  margin: 0;
  padding: ${({ theme }) =>
    `${theme.spaces[40]} ${theme.spaces[22]} ${theme.spaces[40]} ${theme.spaces[16]} `};
  height: auto;
  @media ${device.lg} {
    min-height: 0;
    height: 200px;
    margin: ${({ theme }) => `0 0 -${theme.spaces[102]} `};
  }
`

export default function RealizationsPage ({ data, pageContext }) {
  // staging commit

  const { t } = useTranslation()

  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])

  return (
    <Layout>
      <Seo title={t("Realizacje")} />
      <StyledRealizations>
        <RealizationsTitle />
        <Realizations realizationsList={data.allContentfulRealizations.nodes} />
        <StyledYellowBox data-aos="fade-in" />
      </StyledRealizations>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulRealizations(
      filter: { node_locale: { eq: $language } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        contentful_id
        slug
        title
        type
        boldDescription {
          boldDescription
        }
        mainImage {
          file {
            url
          }
          title
        }
        partnerImg {
          file {
            url
          }
          title
        }
      }
    }
  }
`
