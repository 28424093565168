import React, { useState, useEffect } from "react"
import styled from "styled-components"
import FilterSectionDesktop from "./Filter/Desktop/Filter"
import FilterSectionMobile from "./Filter/Mobile/Filter"
import RealizationPost from "./RealizationsPost"
import { realizationsTypes } from "./Filter/realizationsTypes"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { H2 } from "../shared/Typography/Typography"
import Button from "../shared/Button/Button"
import Aos from "aos"
import "aos/dist/aos.css"

const RealizationsWrapper = styled.div``

const NoData = styled(H2)`
  text-align: center;
  margin: ${({ theme }) => `${theme.spaces[100]} auto`};
`

const StyledLoadMoreButton = styled(Button)`
  margin: ${({ theme }) => `0 auto ${theme.spaces[64]}`};
`

export default function Realizations ({ realizationsList }) {
  const { t } = useTranslation()
  const initLimit = 5
  const [activeFilter, setActiveFilter] = useState(realizationsTypes.all)
  const [limit, setLimit] = useState(initLimit)
  const filteredRealizations =
    activeFilter === realizationsTypes.all
      ? realizationsList
      : realizationsList.filter(el => el.type === activeFilter)
  const shouldRenderLoadMore = filteredRealizations.length > limit

  const handleClickLoadMore = () => {
    setLimit(prevState => prevState + initLimit)
  }
  useEffect(() => {
    Aos.init({ duration: 800, once: true })
  }, [])

  return (
    <RealizationsWrapper>
      <FilterSectionDesktop
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      <FilterSectionMobile
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
      />
      {filteredRealizations?.length === 0 ? (
        <NoData>{t("Brak realizacji")}</NoData>
      ) : (
        filteredRealizations.slice(0, limit).map((el, key) => {
          return (
            <RealizationPost
              title={el.title}
              text={el.boldDescription.boldDescription}
              linkUrl={el.slug}
              linkText={t("Zobacz casestudy")}
              imgUrl={el.mainImage.file.url}
              imgAlt={el.mainImage.file.title}
              srcLogo={el.partnerImg.file.url}
              altLogo={el.partnerImg.file.title}
              key={el.contentful_id}
              data-aos="fade-in"
            ></RealizationPost>
          )
        })
      )}
      {shouldRenderLoadMore && (
        <StyledLoadMoreButton
          sufixIcon
          color="primary"
          onClick={handleClickLoadMore}
          data-aos="fade-in"
        >
          {t("Załaduj więcej")}
        </StyledLoadMoreButton>
      )}
    </RealizationsWrapper>
  )
}
