import React from "react"
import styled from "styled-components"
import { ParagraphRegular, H2 } from "../shared/Typography/Typography"
import { device } from "../shared/styles/breakpoints"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledTitle = styled(H2)`
  padding: ${({ theme }) => `0 0 ${theme.spaces[16]} 0`};
  margin: 0;
  white-space: pre-wrap;
  @media ${device.xs} {
    padding: ${({ theme }) => `0 ${theme.spaces[48]} ${theme.spaces[16]} 0`};
  }
  @media ${device.sm} {
    padding: ${({ theme }) => `0 0 ${theme.spaces[16]} 0`};
    margin: 0 auto;
    max-width: 490px;
  }
`

const StyledText = styled(ParagraphRegular)`
  margin: 0;
`

const StyledTextWrapper = styled.div`
  max-width: 560px;
  padding: ${({ theme }) =>
    `${theme.spaces[64]} ${theme.spaces[16]} ${theme.spaces[40]}`};
  @media ${device.sm} {
    text-align: center;
    margin: 0 auto;
  }
`

export default function RealizationsTitle() {
  const { t } = useTranslation()
  return (
    <StyledTextWrapper>
      <StyledTitle>{t("realizationsTitle")}</StyledTitle>
      <StyledText>{t("realizationsText")}</StyledText>
    </StyledTextWrapper>
  )
}
