import React from "react"
import styled from "styled-components"
import { device } from "../../../shared/styles/breakpoints"
import { realizationsFilters } from "../realizationsFilter"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledFilterSection = styled.div`
  margin: 0 auto;
  max-width: 980px;
  justify-content: space-between;
  margin: ${({ theme }) =>
    `${theme.spaces[40]} ${theme.spaces[60]} ${theme.spaces[32]} ${theme.spaces[100]}`};
  display: none;
  @media ${device.lg} {
    display: flex;
  }
  @media ${device.xl} {
    margin: ${({ theme }) =>
    `${theme.spaces[44]} ${theme.spaces[64]} ${theme.spaces[32]} ${theme.spaces[64]}`};
  }

`

const StyledButton = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme, isActive }) =>
    isActive ? theme.colors.red : theme.colors.black};
  transition: ${({ theme }) => theme.transition};

  &:hover{
    color: ${({ theme }) => theme.colors.red};
  }

  &:hover {
    cursor: pointer;
  }
`
const ButtonText = styled.p`
  font-size: ${({ theme }) => theme.fontSizes[16]};
  line-height: ${({ theme }) => theme.lineHeights[16]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`

export default function FilterSection ({ activeFilter, setActiveFilter }) {
  const { t } = useTranslation()
  return (
    <StyledFilterSection>
      {realizationsFilters.map((el, key) => {
        return (
          <StyledButton
            onClick={() => setActiveFilter(el.value)}
            isActive={activeFilter === el.value}
            key={el.id}
          >
            <ButtonText>{t(el.label)}</ButtonText>
          </StyledButton>
        )
      })}
    </StyledFilterSection>
  )
}
