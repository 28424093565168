import React, { useContext } from "react"
import styled from "styled-components"
import { H4 } from "../Typography/Typography"
import { LayoutContext } from "../../Layout/context/LayoutContext"
import { device } from "../styles/breakpoints"
import { ButtonInternalLink } from "../Link/Link"
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledYellowBox = styled.div`
  min-height: 200px;
  background-color: ${({ theme }) => theme.colors.yellow};
  z-index: 1;
  position: relative;
  padding: ${({ theme }) =>
    `${theme.spaces[40]} ${theme.spaces[22]} ${theme.spaces[40]} ${theme.spaces[16]}`};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.lg} {
    margin: ${({ theme }) => `0 0 -${theme.spaces[96]} `};
  }
`

const StyledText = styled(H4)`
  margin: 0 auto;
  padding: ${({ theme }) => `0 0 ${theme.spaces[24]} `};
  line-height: ${({ theme }) => theme.lineHeights[24]};
  color: ${({ theme }) => theme.colors.black};
  margin: 0;
  text-align: center;
  white-space: pre-wrap;
`

export default function YellowBox({ ...props }) {
  const { setOpenContactForm } = useContext(LayoutContext)
  const { t } = useTranslation()
  return (
    <StyledYellowBox {...props}>
      <StyledText>{t("yellowboxText")}</StyledText>
      <ButtonInternalLink
        sufixIcon
        color="secondary"
        to="/contact"
        onClick={setOpenContactForm}
      >
        {t("yellowboxButton")}
      </ButtonInternalLink>
    </StyledYellowBox>
  )
}
