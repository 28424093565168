import React from 'react';
import styled from 'styled-components';
import Select from 'react-select'
import { realizationsFilters } from "../realizationsFilter"
import { device } from '../../../shared/styles/breakpoints'
import { theme } from '../../../../utils/theme'
import { useTranslation } from "gatsby-plugin-react-i18next"

const StyledSelectWrapper = styled.div`
  max-width: 345px;
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spaces[16]}`};
  margin: ${({ theme }) => `0 auto ${theme.spaces[40]}`};
  display: block;
  box-sizing: border-box;

  @media ${device.lg} {
    display: none;
  }
`
const colourStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? theme.colors.yellow : theme.colors.white,
    color: theme.colors.black,
    padding: `8px 24px`,
    transition: theme.transition,
    border: 'none',
    borderRadius: 0,
    "&:hover": {
      cursor: 'pointer',
      backgroundColor: theme.colors.yellow300
    }
  }),
  control: (provided, state) => ({
    ...provided,
    transiton: theme.transition,
    border: `1px solid ${theme.colors.midGray}`,
    borderRadius: 8,
    outline: 'none',
    boxShadow: state.isFocused ? `0px 0px 0px 2px #ECECEC` : `0px 0px 0px 0px #ECECEC`,
    "&:hover": {
      boxShadow: `0px 0px 0px 2px #ECECEC`,
      border: `1px solid ${theme.colors.midGray}`,
      cursor: 'pointer'
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: theme.colors.darkGray,

  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: theme.colors.gray,
    transition: theme.transition,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)'
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: 8,
    border: `1px solid ${theme.midGray}`
  }),
  menuList: (provided, state) => ({
    ...provided,
    padding: 0,
    borderRadius: 8,
  })
}
export default function FilterSection ({ activeFilter, setActiveFilter }) {
  const { t } = useTranslation()
  const currentValue = realizationsFilters.find((el) => el.value === activeFilter)
  const translatedCurrentValue = { ...currentValue, label: t(currentValue.label) }
  const translatedOptions = realizationsFilters.map((el) => ({
    ...el,
    label: t(el.label)
  }))
  const handleChange = (selectedOption) => {
    setActiveFilter(selectedOption.value)

  }
  return (
    <StyledSelectWrapper>
      <Select
        value={translatedCurrentValue}
        options={translatedOptions}
        styles={colourStyles}
        isSearchable={false}
        components={{
          IndicatorSeparator: () => null
        }}
        onChange={handleChange}
      />
    </StyledSelectWrapper>
  )
}
